<template>
  <div class="row newspaper">
    <div class="col-md-12 mt-2">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col md="3">
              <b-form-select v-model="status" :options="statusOptions" @input="statusChange"></b-form-select>
            </b-col>
            <b-col>
              <b-button @click="onClearSearch">Clear</b-button>
            </b-col>
          </b-row>
          <b-row class="py-2">
            <b-col lg="5" md="6">
              <input
                placeholder="Type Here to Search Newspaper" 
                class="form-control" 
                type="search"
                v-model="query_search"
                @input="onSearch" 
                v-on:keyup.enter="onSearch"
              >
              <small v-if="typing"> <em> typing ... </em> </small>
            </b-col>
            <b-col class="d-flex justify-content-end pt-1">
              <span class="pr-2">
                <router-link class="btn btn-sm btn-dark" 
                  :to="{ name: NewspaperCreatePath.name }"
                  >
                  <i class="fa fa-plus"></i>
                </router-link>
              </span>
              <span>
                <button class="btn btn-sm btn-dark" @click="reloadPage">
                  <i class="fa fa-refresh"></i>
                </button>
              </span>
              <span>
                <label for="" class="col-sm-auto">Count </label>
              </span>
              <span>
                <strong class="col-sm-auto">{{ formatPriceBasic(totalRows) }}</strong>
              </span>
            </b-col>
          </b-row>
          <TableBooks :books="Newspaper" :currentPage="currentPage" :perPage="perPage" :isLoading="isLoading" :reloadPage="reloadPage" />
        </div>
        <b-row class="justify-content-right ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              @change="onClickPage"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import TableBooks from "../../components/ebook/table.vue";
import { NewspaperCreatePath } from "../../router/content";
import constant from "../../store/constant";

const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map(item => { return {text: item[0], value: item[1]} })
export default {
  name: "newspaper",
  components: {
    TableBooks
  },
  data() {
    return {
      NewspaperCreatePath,
      query_search: null,
      status: null,
      statusOptions: [
        {text: '---Status [All]---', value: null},
        ...ITEM_STATUS
      ],
      currentPage: 1,
      perPage: 50,
      debounce: null,
      typing: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Newspapers | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  computed: {
    ...mapGetters("newspaper", ["Newspaper"]),
    ...mapState({
      isLoading: (state) => state.newspaper.isLoading,
      isError: (state) => state.newspaper.isError,
      totalRows: (state) => state.newspaper.totalRows,
    }),
  },
  created() {
    this.actionFirst();
  },
  methods: {
    ...mapActions('newspaper', ['fetchNewsPaper','searchNewsPaper']),
    actionFirst() {
      const query = this.$route.query;
      this.currentPage = query.page ?? this.currentPage;
      this.perPage = query.limit ?? this.perPage;
      this.query_search = query.q ?? this.query_search;
      this.status = query.status ?? this.status;
      this.reloadPage();
    },
    actionGetNewsPaper() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchNewsPaper(payload);
      this.setQuery(payload);
    },
    actionSearchNewsPaper() {
      const payload = {
        q: this.query_search ?? '',
        page: this.currentPage,
        limit: this.perPage,
        status: this.status,
      };
      this.searchNewsPaper(payload);
      this.setQuery(payload);
    },
    onSearch(event) {
      this.query_search = event.target.value;
      this.currentPage = 1;
      if (!this.query_search) {
        this.actionGetNewsPaper();
        clearTimeout(this.debounce);
        return;
      }
      this.typing = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = false;
        this.actionSearchNewsPaper();
      }, 600);
    },
    onClearSearch() {
      this.query_search = null;
      this.vendor = [];
      this.status = null;
      this.currentPage = 1;
      this.reloadPage();
    },
    onClickPage(page) {
      this.currentPage = page;
      this.reloadPage();
    },
    reloadPage() {
      if (!this.query_search && !this.status) this.actionGetNewsPaper();
      else this.actionSearchNewsPaper();
    },
    statusChange() {
      this.actionSearchNewsPaper();
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return "0";
    },
    setQuery(q = {}) {
      this.$router.push({ path: this.$route.path, state: q }).catch(() => {});
    }
  },
};
</script>

<style scoped>
.newspaper {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
